@use "./stylesheets/responsiveness";
@use "./stylesheets/typography";

.landing-component {
  position: relative;
  background: linear-gradient(180deg, #81CBD6 0%, #E6F3FF 51.56%, #8296D9 100%);

  @include responsiveness.screen(smallest, phone, tablet) {
    background: linear-gradient(180deg, #CDFCFF 13.21%, #8296D9 100%);
  }

  &__container {
    display: flex;
    justify-content: center;
    padding: 80px 4vw;

    // use column view if the screen is smaller than 768px
    @include responsiveness.screen(smallest, phone, tablet) {
      flex-direction: column;
      padding: 40px 6vw 30px;
    }
  }

  &__section {
    width: 50%;
    max-width: 900px;
    align-self: center;
    z-index: 2;

    @include responsiveness.screen(smallest, phone, tablet) {
      width: 100%;
      align-self: unset;
    }
  }

  &__visual {
    display: block;
    margin: 0 auto;
    width: 80%;
    height: auto;

    @include responsiveness.screen(smallest) {
      width: 90%;
    }

    @include responsiveness.screen(phone, tablet, big-desktop) {
      width: 55%;
    }
  }

  &__text-box {
    margin-top: 12%;
    padding: 28px 30px;
    max-width: 80%;
    background: var(--color-bg-primary);
    border-radius: 30px;

    @include responsiveness.screen(large-tablet, small-laptop) {
      margin-top: 0;
      padding: 18px 20px;
      max-width: 90%;
    }

    @include responsiveness.screen(smallest, phone, tablet) {
      margin-top: -40px;
      max-width: 100%;
      padding: 0;
      background: unset;
    }
  }

  &__title {
    margin-bottom: 15px;
    @include typography.title;
    text-shadow: 0 4px 8px #95B0FF;

    @include responsiveness.screen(smallest, phone, tablet) {
      margin-bottom: 30px;
      padding: 12px 10px;
      background: var(--color-bg-primary);
      border-radius: 15px;
      text-align: center;
    }
  }

  &__textContainer {
    margin-bottom: 15px;
    @include typography.text;

    @include responsiveness.screen(smallest, phone, tablet) {
      padding: 25px 4vw;
      background: var(--color-bg-primary);
      border-radius: 30px;
    }
  }

  &__description {
    margin-bottom: 10px;
  }

  &__subscriptionContainer {
    margin-top: 20px;
  }

  &__buttonContainer {
    display: flex;
    justify-content: space-around;
    margin-top: 20px;
  }

  &__button {
    flex-grow: 1;
    min-width: unset;
    min-height: 30px;
    border-radius: 10px;

    &:first-child {
      margin-right: 12px;
    }

    @include responsiveness.screen(smallest, phone, tablet) {
      padding: 4px 2px;
    }
  }

  &__hills {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: auto;
    z-index: 1;

    @include responsiveness.screen(smallest, phone, tablet) {
      height: 100px;
    }
  }

  &__hillsMobile {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 60%;
    z-index: 0;
  }
}
