@use "./stylesheets/responsiveness";
@use "./stylesheets/typography";

.faq-component {
  margin-top: -1px;
  padding-top: 40px;
  padding-bottom: 40px;
  background-size: cover;
  background: linear-gradient(#9DD7E5, #70AACB);
  &__questionsandanswers {
    display: flex;
    flex-shrink: 0;
    flex-direction: row;
    flex-wrap: wrap;
  }
  &__box {
    min-height: 1000px;
    margin: auto;
    border: 7px solid rgba(255, 255, 255, 0.75);
    border-radius: 59px;
    padding: 10px;
    display: table;
    // height: 1400px;
    width: 80%;
    max-width: 1400px;
    @include responsiveness.screen(smallest) {
      padding: 0px;
    }
    @include responsiveness.screen(large-tablet) {
      width: 90%;
    }
  }
  &__faqtitle {
    font-family: "Source Sans Pro", sans-serif;
    font-style: normal;
    font-weight: bold;
    @include responsiveness.font($responsive: 4.1vw, $min: 35px, $max: 60px);
    text-align: center;
    color: var(--color-text-primary);
    margin-bottom: 10px;
  }
  &__line {
    width: 25%;
    height: 11px;
    background: rgba(255, 255, 255, 0.73);
    margin: auto;
    // @include responsiveness.screen(desktop, big-desktop) {
    //   width: 20px;
    // }
  }
  &__question {
    font-family: Lato, sans-serif;
    font-style: normal;
    font-weight: 800;
    font-size: 24px;
    line-height: auto;
    color: #ffffff;
    cursor: pointer;
  }
  &__answer {
    font-family: Lato, sans-serif;
    font-style: normal;
    font-weight: normal;
    @include responsiveness.font($responsive: 4.1vw, $min: 18px, $max: 24px);
    line-height: auto;
    color: #ffffff;
    display: block;
  }

  &__questionsandanswers:after {
    display: table;
    clear: both;
  }
  &__column {
    width: 33%;
    @include responsiveness.screen(smallest, phone, tablet) {
      width: 100%;
    }
    padding-right: 2px;
    display: flex;
    flex-direction: column;
  }
  &__questionsandanswersitems {
    // width: 30vh;
    margin: 20px;
  }
}
