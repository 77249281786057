@use "~stylesheets/typography";

.Button {
  margin: 0;
  padding: 8px 10px;
  min-width: 150px;

  cursor: pointer;
  user-select: none;
  border: 3px solid var(--color-bg-secondary);
  box-sizing: border-box;
  border-radius: 14px;
  @include typography.button-text;
  text-align: center;
  text-decoration: none;

  // default styling - transparent
  color: #FFFFFF;
  background: transparent;

  &:hover {
    color: #D9Efff;
  }

  &:disabled {
    cursor: not-allowed;
    border: 3px solid #8C8C8C;
    color: #8C8C8C;
    background: #505050;
  }

  &--primary {
    background: var(--color-bg-secondary);
  }

  &--secondary {
    color: var(--color-bg-primary);
    background: var(--color-text-primary);

    &:hover {
      color: var(--color-bg-secondary);
    }
  }

}
