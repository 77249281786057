@use "./stylesheets/responsiveness";
@import "./stylesheets/fonts";
@import "./stylesheets/variables";


html,
body,
#root,
#root > div {
  width: 100%;
  height: 100vh;
  margin: 0;
  padding: 0;
  overscroll-behavior: contain;
}

.App {
  @include responsiveness.screen(smallest) {

  }
  @include responsiveness.screen(phone) {

  }
  @include responsiveness.screen(tablet) {

  }
  @include responsiveness.screen(large-tablet) {

  }
  @include responsiveness.screen(small-laptop) {

  }
  @include responsiveness.screen(desktop) {

  }
  @include responsiveness.screen(big-desktop) {

  }
}
