/**
Sample usage::
   @use "~stylesheets/responsiveness"
   @include responsiveness.screen(smallest) {
   }

   @include responsiveness.screen(phone, tablet, large-tablet) {
   }
 */
@use 'sass:math';

@mixin screen($breakpoints...) {
  @each $breakpoint in $breakpoints {
    @if ($breakpoint == smallest) {
      @media (max-width: 350px) {
        @content;
      }
    }

    @if ($breakpoint == phone) {
      @media (min-width: 350px) and (max-width: 500px) {
        @content;
      }
    }

    @if ($breakpoint == tablet) {
      @media (min-width: 500px) and (max-width: 768px) {
        @content;
      }
    }

    @if ($breakpoint == large-tablet) {
      @media (min-width: 768px) and (max-width: 992px) {
        @content;
      }
    }

    @if ($breakpoint == small-laptop) {
      @media (min-width: 992px) and (max-width: 1200px) {
        @content;
      }
    }

    @if ($breakpoint == desktop) {
      @media (min-width: 1200px) and (max-width: 1600px) {
        @content;
      }
    }

    @if ($breakpoint == big-desktop) {
      @media (min-width: 1601px) {
        @content;
      }
    }
  }
}


///
/// Viewport sized typography with minimum and maximum values
///
/// @author Eduardo Boucas (@eduardoboucas)
///
/// @param {Number}   $responsive  - Viewport-based size
/// @param {Number}   $min         - Minimum font size (px)
/// @param {Number}   $max         - Maximum font size (px)
///                                  (optional)
/// @param {Number}   $fallback    - Fallback for viewport-
///                                  based units (optional)
///
/// @example scss - 5vw font size (with 50px fallback),
///                 minumum of 35px and maximum of 150px
///  @include font(5vw, 35px, 150px, 50px);
///
@mixin font($responsive, $min, $max: false, $fallback: false) {
  $responsive-unitless: math.div($responsive, ($responsive - $responsive + 1));
  $dimension: if(unit($responsive) == "vh", "height", "width");
  $min-breakpoint: math.div($min * 100, $responsive-unitless );

  @media (max-#{$dimension}: #{$min-breakpoint}) {
    font-size: $min;
  }

  @if $max {
    $max-breakpoint: math.div($max * 100, $responsive-unitless);

    @media (min-#{$dimension}: #{$max-breakpoint}) {
      font-size: $max;
    }
  }

  @if $fallback {
    font-size: $fallback;
  }

  font-size: $responsive;
}
