@use "~stylesheets/typography";
@use "~stylesheets/responsiveness";

.team-view {
  background-color: #34486B;

  &__our-team {
    @include typography.our-team-text;
    width: 100%;
    text-align: center;
    padding-top: 50px;
  }

  &__small-message {
    padding-top: 50px; 
    text-align: center;
    padding-left: 10px;
    padding-right: 10px;
    @include typography.our-team-message;
  }

  &__team {
    display: flex;
    justify-content: center;
    margin-bottom: 50px;
  }
  &__special-thanks-container {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-bottom: 40px;
    &--msg {
      text-align:center;
      @include typography.our-team-message;
      width: 500px;
      max-width: 100%;
      padding-left: 20px;
      padding-right: 20px;
      @include responsiveness.screen(smallest, phone, tablet) {
        font-size: 24px;
      }
    }
  }
}
