@use "./stylesheets/responsiveness";
@use "./stylesheets/typography";

.about-box {
    z-index: 1;
    border-radius: 33px;
    width: 70%;
    max-width: 1500px;
    height: 100%;
    background-color: var(--color-dark-navy-blue);
    margin: 50px 0 50px 0;
    &__title {
        display: flex;
        flex-direction: column;
        justify-content: center;
        min-height: 50px;
        background-color: var(--color-text-primary);
        color: var(--color-dark-navy-blue);
        font-family: Source Sans Pro, "sans-serif";
        font-style: normal;
        font-weight: bold;
        padding: 0 2% 0 2%;
        border-top-left-radius: 33px;
        border-top-right-radius: 33px;
        @include responsiveness.font($responsive: 3.8vw, $min: 35px, $max: 80px);
        @include responsiveness.screen(smallest, phone) {
            @include responsiveness.font($responsive: 7.1vw, $min: 20px, $max: 40px);
            text-align: center;
            padding: 0 26px 0 30px;
        }
        @include responsiveness.screen(smallest) {
            padding: 0;
            @include responsiveness.font($responsive: 7.1vw, $min: 20px, $max: 40px);
            text-align: center;
        }
        @include responsiveness.screen(tablet) {
            text-align: center;
        }
    }
    &__description {
        font-family: Lato, "sans-serif";
        color: var(--color-text-primary);
        height:100%;
        border-bottom-left-radius: 33px;
        border-bottom-right-radius: 33px;
        padding: 1% 4% 2% 4%;
        @include responsiveness.font($responsive: 2.7vw, $min: 20px, $max: 32px);

        @include responsiveness.screen(smallest, phone, tablet) {
            padding: 10px 26px 20px 30px;
            line-height: 31px;
            @include responsiveness.font($responsive: 5.1vw, $min: 20px, $max: 25px);
        }
    }
} 