@use "./stylesheets/responsiveness";

.about-component {
  background: linear-gradient(180.01deg, #8296D9 0%, #bb95e0 100%);

  position: relative;
    z-index: 0;
    margin-top: -1px;
    &__background {
        position: absolute;
        width: 100%;
        height: 100%;
        z-index:-1;
        &.mobile {
            @include responsiveness.screen(smallest, phone) {
                display:block;
            }
            @include responsiveness.screen(tablet, large-tablet, small-laptop, desktop, big-desktop) {
                display: none;
            }
        }
        &.desktop {
            @include responsiveness.screen(smallest, phone) {
                display: none;
            }
            @include responsiveness.screen(tablet, large-tablet, small-laptop, desktop, big-desktop) {
                display: block;
            }
        }
    }
    &__information {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
} 
