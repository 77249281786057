@use "./stylesheets/responsiveness";
@use "./stylesheets/typography";

.partners-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    &__header {
        padding: 0 20px 0 20px;
        font-family: "Source Sans Pro", "sans-serif";
        font-style: normal;
        text-align: center;
        color: #FFFFFF;
        border: 7px solid #FFFFFF;
        border-top: none;
        border-left: none;
        border-right: none;
        @include responsiveness.font($responsive: 3.1vw, $min: 22px, $max: 50px);
        @include responsiveness.screen(smallest, phone) {
            line-height: 22px;
            border-width: 4px;
        }
        @include responsiveness.screen(tablet, large-tablet) {
            line-height: 36px;
        }
        @include responsiveness.screen(small-laptop, desktop) {
            line-height: 63px;
        }
        @include responsiveness.screen(big-desktop) {
            line-height: 70px;
        }
    }

    &__rows {
        margin-top:2vh;
    }
    &__partners {
        margin: 0 5vw 0 5vw;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        @include responsiveness.screen(small-laptop, desktop, big-desktop) {
            justify-content: space-between;
        }
    }
    &__partner {
        margin: 0 2vw 0 2vw;
        @include responsiveness.screen(smallest, phone) {
            height: 50%;
        }
        @include responsiveness.screen(tablet, large-tablet) {
            height: 70%; 
        }
        @include responsiveness.screen(small-laptop, desktop) {
            height: 90%;
        }
        @include responsiveness.screen(big-desktop) {
            height: 110%;
        }
    }
    &__image {
        height: 100%;
        width: 100%;
        object-fit:cover;
        @include responsiveness.screen(smallest, phone) {
            max-height: 70px;
        }
        @include responsiveness.screen(tablet, large-tablet) {
            max-height: 90px; 
        }
        @include responsiveness.screen(small-laptop, desktop) {
            max-height: 120px;
        }
        @include responsiveness.screen(big-desktop) {
            max-height: 150px;
        }
    }
}
