@use "./stylesheets/responsiveness";
@use "./stylesheets/typography";

.footer-component {
  position: relative;
  margin-top: -1px;

  &--with-graphics {
    padding-top: 40vw;

    .footer-component__text {
      padding-top: 70px;

      @include responsiveness.screen(smallest) {
        padding-top: 15px;
      }

      @include responsiveness.screen(phone) {
        padding-top: 25px;
      }

      @include responsiveness.screen(tablet) {
        padding-top: 40px;
      }
    }

    .footer-component__graphics {
      position: absolute;
      bottom: 0;
      pointer-events: none;
      z-index: 0;
    }
  }

  &--icons {
    display: flex;
    justify-content: center;
    gap: 2%;
    z-index: 1;
    @include responsiveness.screen(smallest) {
      gap: 0;
    }
  }

  &__link--wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    padding: 1%;
    width: 6vw;
    height: 6vw;
    max-width: 100px;
    max-height: 100px;
    min-width: 45px;
    min-height: 45px;
    @include responsiveness.screen(smallest, phone) {
     padding: 0;
    }
    z-index: 1;
  }

  &__link--icon {
    width: 80%;
    height: 80%;
    filter: drop-shadow(0px 1px 3px rgb(210, 213, 255));
    @include responsiveness.screen(smallest, phone) {
      width: 70%;
      height: 70%;
    }
  }

  &__link--icon:hover {
    filter: drop-shadow(0px 1px 4px rgb(210, 213, 255));
  }

  &__text {
    position: relative;
    display: flex;
    flex-direction: column;
    font-family: "Source Sans Pro", sans-serif;
    font-style: italic;
    @include responsiveness.font($responsive: 2.3vw, $min: 10px, $max: 28px);
    z-index: 1;
  }

  &__text--copyright {
    position: absolute;
    right: 10%;
    bottom: 10%;
    @include responsiveness.screen(smallest) {
      right: 0;
    }
  }

  &__hills {
    width: 100%;
    height: 10%;

    &--desktop {
      @include responsiveness.screen(smallest, phone) {
        display: none;
      }
    }

    &--mobile {
      display: none;
      @include responsiveness.screen(smallest, phone) {
        display: block;
      }
    }
  }
}
