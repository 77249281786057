@use "./stylesheets/responsiveness";
@use "./stylesheets/typography";

.speaker-card {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 15em;
  height: 18em;
  margin: 5em 50px;

  background: #4A5887;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25), 0 4px 4px 1px #B1CFFC;
  border-radius: 24px;

  @include responsiveness.screen(tablet, large-tablet, small-laptop) {
    margin-left: 20px;
    margin-right: 20px;
  }

  @include responsiveness.screen(smallest, phone) {
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 2em;
  }

  &__img-container {
    position: absolute;
    display: flex;
    justify-content: center;
    top: -5em;
    width: 12em;
    height: 12em;
    filter: drop-shadow(0px 4px 4px rgba(6, 6, 6, 0.42));
    border-radius: 100px;
  }

  &__img-border {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &__image {
    align-self: center;
    border-radius: 100px;
    width: 85%;
    height: 85%;
    object-fit: cover;
    user-select: none;
    // we can't use gradient for the border, so we're using a svg instead
    //box-sizing: border-box;
    //border: 10px solid #e29dbf;
  }

  &__container {
    margin-top: 5em;
    width: 90%;
    @include typography.header-text;
  }

  &__name {
    font-size: 26px;
    color: #F9EEFF;
  }

  &__title {
    font-size: 20px;
    color: #FFE8F3;
    font-weight: normal;
    //font-style: italic;
  }
}
