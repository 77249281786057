@use "~stylesheets/responsiveness";
@use "~stylesheets/typography";

.NavBar {
  overflow: visible;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: 12px 150px;
  @include responsiveness.screen(big-desktop) {
    padding: 12px 180px;
  }
  &__links {
    &--link {
      margin: 5px 18px;
      color: var(--color-text-primary);
      text-decoration: none;
      @include typography.button-text;
      &:hover {
        color: var(--color-text-secondary);
      }
    }
    @include responsiveness.screen(small-laptop, large-tablet, tablet, phone, smallest) {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: 50px;
      right: 0px;
      width: 100vw;
      height: 25%;
      border-radius: 0% 0% 10% 10%;
      box-shadow: -1px 5px 10px #233A59cD;
      z-index: 10;
    }
    @include responsiveness.screen(small-laptop, large-tablet) {
      top: 100px;
    }
  }
  @include responsiveness.screen(small-laptop, large-tablet, tablet, phone, smallest) {
    padding: 25px;
  }
  @include responsiveness.screen(small-laptop, large-tablet) {
    padding: 50px;
  }
}
.NavBar__logout {
  background: none;
  border: none;
  color: var(--color-text-primary);
  margin: 0px 18px;
  @include typography.button-text;
}
.NavBar__logout:hover {
  color: var(--color-text-secondary);
}
.Navbar__mobile {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.hamburgerButton {
  position: absolute;
  border: none;
  cursor: pointer;
  background: none;
  right: 8%;
  top: 0px;
  margin: 1%;
  padding: 10px;
  @include responsiveness.screen(small-laptop, large-tablet) {
    top: 20px;
    right: 6%;
    transform: scale(150%);
  }
}
.hamburgerButton__open {
  position: absolute;
  border: none;
  cursor: pointer;
  background: none;
  right: 8%;
  top: 0px;
  margin: 1%;
  padding: 10px;
  @include responsiveness.screen(small-laptop, large-tablet) {
    top: 20px;
    right: 6%;
    transform: scale(150%);
  }
}
.mid {
  transition: all .3s ease;
}
.top {
  transition: all .3s ease;
  transform-origin: 0 0;
}
.bottom {
  transition: all .3s ease;
  transform-origin: 0 100%;
}

.hamburgerButton__open svg rect.mid {
  fill: #00000000;
  transform: scaleX(0%);
}
.hamburgerButton__open svg rect.top {
  transform: rotate(45deg) translate(6px, -5px);
}
.hamburgerButton__open svg rect.bottom {
  transform: rotate(-45deg) translate(6px, 5px);
}
