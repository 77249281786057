$color-bg-primary: #36628b;
$color-bg-secondary: #72bfcf;
$color-text-primary: #fff;
$color-text-secondary: #d3e2eb;

$color-navy-blue: #385f9a;
$color-gray: rgba(0, 0, 0, 0.25);
$color-teal: rgba(70, 104, 135, 0.49);
$color-light-pink: #de98df;
$color-lavender: #b0b6f7;
$color-sky-blue: #3792c6;
$color-light-navy-blue: rgba(67, 113, 183, 0.67);
$color-dark-navy-blue: #325A89;
$color-black: #000000;

:root {
  --color-bg-primary: #{$color-bg-primary};
  --color-bg-secondary: #{$color-bg-secondary};

  --color-text-primary: #{$color-text-primary};
  --color-text-secondary: #{$color-text-secondary};

  --color-navy-blue: #{$color-navy-blue};
  --color-gray: #{$color-gray};
  --color-teal: #{$color-teal};
  --color-light-pink: #{$color-light-pink};
  --color-lavender: #{$color-lavender};
  --color-sky-blue: #{$color-sky-blue};
  --color-light-navy-blue: #{$color-light-navy-blue};
  --color-dark-navy-blue: #{$color-dark-navy-blue};
  --color-black: #{$color-black};
}
