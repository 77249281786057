@use "./stylesheets/responsiveness";
@use "./stylesheets/typography";

.schedule-table {
  position: relative;
  width: 100%;

  &__table {
    margin: 0 50px;
    padding: 20px 30px 40px;
    background: linear-gradient(180deg, rgba(91, 62, 128, 0.64) 0%, rgba(86, 114, 170, 0.78) 100%);
    box-shadow: 0 4px 8px rgba(193, 194, 207, 0.74);
    border-radius: 20px;

    font-family: "Lato", sans-serif;
    font-style: normal;
    font-weight: normal;

    @include responsiveness.screen(smallest, phone) {
      margin: 0;
      padding-left: 10px;
      padding-right: 10px;
    }
  }

  &__header {
    padding: 0 0 10px;
    text-align: center;
    border-bottom: 2px solid #9fc5ff;

    font-weight: bold;
    color: #ced6fa;
    font-size: 28px;

    @include responsiveness.screen(desktop, big-desktop) {
      font-size: 36px;
    }

    @include responsiveness.screen(smallest, phone) {
      font-size: 20px;
    }
  }

  &__container {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    height: 100%;
    @include responsiveness.screen(desktop, big-desktop) {
      min-height: 800px;
    }
  }

  &__event {
    display: flex;
    flex-wrap: nowrap;
    border-bottom: 2px solid #9fc5ff;
    color: #d3ddff;
    font-size: 20px;
    width: 100%;
    height: 60px;

    @include responsiveness.screen(big-desktop) {
      font-size: 30px;
      max-height: 80px;
      height: 80px;
    }
    @include responsiveness.screen(desktop) {
      font-size: 26px;
      max-height: 80px;
    }
    @include responsiveness.screen(smallest, phone, tablet) {
      font-size: 16px;
    }

    &__time {
      margin: auto 0;
      padding: 0 20px;
      width: 16%;
      min-width: 16%;
      color: #E2C8EE;
      text-align: center;

      @include responsiveness.screen(tablet) {
        padding: 0 10px;
        width: 20%;
        min-width: 20%;
      }

      @include responsiveness.screen(smallest, phone) {
        padding: 0 10px;
        width: 24%;
        min-width: 24%;
      }

      @include responsiveness.screen(smallest) {
        padding: 0 5px;
        width: 75px;
        min-width: 75px;
      }
    }

    &__divider {
      min-width: 2px;
      width: 2px;
      height: 100%;
      background: #9fc5ff;
    }

    &__text {
      margin: auto 0;
      padding: 0 20px;

      @include responsiveness.screen(smallest, phone, tablet) {
        padding: 0 10px;
      }
    }
  }

  &__arrows {
    @include responsiveness.screen(smallest, phone) {
      display: flex;
      flex-wrap: nowrap;
      justify-content: space-around;
      margin: 20px 0 0;
    }
  }

  &__button {
    position: absolute;
    top: 2em;
    width: 30px;
    height: 30px;
    cursor: pointer;
    user-select: none; // so users don't select text when double clicking the arrows

    &--previous {
      left: 0;
    }

    &--next {
      right: 0;
    }

    &--disabled {
      cursor: not-allowed;
    }

    @include responsiveness.screen(smallest, phone) {
      position: relative;
      top: 0;
    }
  }
}
