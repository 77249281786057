// Usable Fonts

/* source-sans-pro-regular - latin */
@font-face {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/source-sans-pro-v14-latin-regular.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("../fonts/source-sans-pro-v14-latin-regular.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("../fonts/source-sans-pro-v14-latin-regular.woff2")
      format("woff2"),
    /* Super Modern Browsers */
      url("../fonts/source-sans-pro-v14-latin-regular.woff") format("woff"),
    /* Modern Browsers */ url("../fonts/source-sans-pro-v14-latin-regular.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("../fonts/source-sans-pro-v14-latin-regular.svg#SourceSansPro")
      format("svg"); /* Legacy iOS */
}
/* source-sans-pro-700 - latin */
@font-face {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 700;
  src: url("../fonts/source-sans-pro-v14-latin-700.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("../fonts/source-sans-pro-v14-latin-700.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("../fonts/source-sans-pro-v14-latin-700.woff2")
      format("woff2"),
    /* Super Modern Browsers */
      url("../fonts/source-sans-pro-v14-latin-700.woff") format("woff"),
    /* Modern Browsers */ url("../fonts/source-sans-pro-v14-latin-700.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("../fonts/source-sans-pro-v14-latin-700.svg#SourceSansPro")
      format("svg"); /* Legacy iOS */
}

/* b612-regular - latin */
@font-face {
  font-family: "B612";
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/b612-v5-latin-regular.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("../fonts/b612-v5-latin-regular.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("../fonts/b612-v5-latin-regular.woff2") format("woff2"),
    /* Super Modern Browsers */ url("../fonts/b612-v5-latin-regular.woff")
      format("woff"),
    /* Modern Browsers */ url("../fonts/b612-v5-latin-regular.ttf")
      format("truetype"),
    /* Safari, Android, iOS */ url("../fonts/b612-v5-latin-regular.svg#B612")
      format("svg"); /* Legacy iOS */
}
/* lato-regular - latin */
@font-face {
  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/lato-v20-latin-regular.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("../fonts/lato-v20-latin-regular.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("../fonts/lato-v20-latin-regular.woff2") format("woff2"),
    /* Super Modern Browsers */ url("../fonts/lato-v20-latin-regular.woff")
      format("woff"),
    /* Modern Browsers */ url("../fonts/lato-v20-latin-regular.ttf")
      format("truetype"),
    /* Safari, Android, iOS */ url("../fonts/lato-v20-latin-regular.svg#Lato")
      format("svg"); /* Legacy iOS */
}
/* lato-700 - latin */
@font-face {
  font-family: "Lato";
  font-style: normal;
  font-weight: 700;
  src: url("../fonts/lato-v20-latin-700.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("../fonts/lato-v20-latin-700.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("../fonts/lato-v20-latin-700.woff2") format("woff2"),
    /* Super Modern Browsers */ url("../fonts/lato-v20-latin-700.woff")
      format("woff"),
    /* Modern Browsers */ url("../fonts/lato-v20-latin-700.ttf")
      format("truetype"),
    /* Safari, Android, iOS */ url("../fonts/lato-v20-latin-700.svg#Lato")
      format("svg"); /* Legacy iOS */
}

/* lato-700italic - latin */
@font-face {
  font-family: "Lato";
  font-style: italic;
  font-weight: 700;
  src: url("../fonts/lato-v20-latin-700italic.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("../fonts/lato-v20-latin-700italic.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("../fonts/lato-v20-latin-700italic.woff2") format("woff2"),
    /* Super Modern Browsers */ url("../fonts/lato-v20-latin-700italic.woff")
      format("woff"),
    /* Modern Browsers */ url("../fonts/lato-v20-latin-700italic.ttf")
      format("truetype"),
    /* Safari, Android, iOS */ url("../fonts/lato-v20-latin-700italic.svg#Lato")
      format("svg"); /* Legacy iOS */
}

/* source-sans-pro-600italic - latin */
@font-face {
  font-family: "Source Sans Pro";
  font-style: italic;
  font-weight: 600;
  src: url("../fonts/source-sans-pro-v14-latin-600italic.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("../fonts/source-sans-pro-v14-latin-600italic.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("../fonts/source-sans-pro-v14-latin-600italic.woff2")
      format("woff2"),
    /* Super Modern Browsers */
      url("../fonts/source-sans-pro-v14-latin-600italic.woff") format("woff"),
    /* Modern Browsers */
      url("../fonts/source-sans-pro-v14-latin-600italic.ttf") format("truetype"),
    /* Safari, Android, iOS */
      url("../fonts/source-sans-pro-v14-latin-600italic.svg#SourceSansPro")
      format("svg"); /* Legacy iOS */
}

/* montserrat-regular - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/montserrat-v18-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/montserrat-v18-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/montserrat-v18-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/montserrat-v18-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('../fonts/montserrat-v18-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/montserrat-v18-latin-regular.svg#Montserrat') format('svg'); /* Legacy iOS */
}

/* montserrat-500 - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  src: url('../fonts/montserrat-v18-latin-500.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/montserrat-v18-latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/montserrat-v18-latin-500.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/montserrat-v18-latin-500.woff') format('woff'), /* Modern Browsers */
       url('../fonts/montserrat-v18-latin-500.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/montserrat-v18-latin-500.svg#Montserrat') format('svg'); /* Legacy iOS */
}